// COMPOSABLE
// IMPORT LIBRERIE VARIE
import { ref } from "vue";

export function useCropper() {
  const cropperHtmlElRef = ref(null);
  const estensioneImmagineOriginale = ref("");
  const nomeImmagineOriginale = ref("");
  const isRemovingGarbage = ref(false);
  const image = ref({
    src: null,
    type: null,
  });
  function getMimeType(file, fallback = null) {
    const byteArray = new Uint8Array(file).subarray(0, 4);
    let header = "";
    for (let i = 0; i < byteArray.length; i++) {
      header += byteArray[i].toString(16);
    }
    switch (header) {
      case "89504e47":
        return "image/png";
      case "47494638":
        return "image/gif";
      case "ffd8ffe0":
      case "ffd8ffe1":
      case "ffd8ffe2":
      case "ffd8ffe3":
      case "ffd8ffe8":
        return "image/jpeg";
      default:
        return fallback;
    }
  }

  const crop = () => {
    const { canvas } = cropperHtmlElRef.value.getResult();
    canvas.toBlob(() => {
      // Do something with blob: upload to a server, download and etc.
    }, image.value.type);
  };
  const reset = () => {
    image.value = {
      src: null,
      type: null,
    };
  };
  const loadImage = (event) => {
    // Reference to the DOM input element
    const { files } = event.target;
    // Ensure that you have a file before attempting to read it
    if (files && files[0]) {
      // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      if (image.value.src) {
        URL.revokeObjectURL(image.value.src);
      }
      // 2. Create the blob link to the file to optimize performance:
      const blob = URL.createObjectURL(files[0]);

      // Extract the file extension from the file name
      const fileName = files[0].name;
      nomeImmagineOriginale.value = fileName.slice(
        0,
        fileName.lastIndexOf(".")
      );
      estensioneImmagineOriginale.value = fileName
        .split(".")
        .pop()
        .toLowerCase();

      // 3. The steps below are designated to determine a file mime type to use it during the
      // getting of a cropped image from the canvas. You can replace it them by the following string,
      // but the type will be derived from the extension and it can lead to an incorrect result:
      //
      // this.image = {
      //    src: blob;
      //    type: files[0].type
      // }

      // Create a new FileReader to read this image binary data
      const reader = new FileReader();
      // Define a callback function to run, when FileReader finishes its job
      reader.onload = (e) => {
        // Note: arrow function used here, so that "this.image" refers to the image of Vue component
        image.value = {
          // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
          src: blob,
          // Determine the image type to preserve it during the extracting the image from canvas:
          type: getMimeType(e.target.result, files[0].type),
        };
      };
      // Start the reader job - read file as a data url (base64 format)
      reader.readAsArrayBuffer(files[0]);
    }
  };
  const uploadImage = () => {
    const { canvas } = cropperHtmlElRef.value.getResult();
    if (canvas) {
      const form = new FormData();
      canvas.toBlob((blob) => {
        form.append("file", blob);

        // You can use axios, superagent and other libraries instead here
        fetch("http://example.com/upload/", {
          method: "POST",
          body: form,
        });
        // Perhaps you should add the setting appropriate file format here
      }, estensioneImmagineOriginale.value);
    }
  };
  const file = ref(null);
  const setFileToSend = async () => {
    return new Promise((resolve) => {
      const { canvas } = cropperHtmlElRef.value.getResult();
      if (canvas) {
        canvas.toBlob((blob) => {
          file.value = blob;
          resolve(true);
        }, estensioneImmagineOriginale.value);
        console.log("canvas", canvas, file);
      } else {
        resolve(false);
      }
    });
  };
  const cropV2 = async () => {
    return new Promise((resolve) => {
      const { canvas } = cropperHtmlElRef.value.getResult();
      if (canvas) {
        canvas.toBlob((blob) => {
          file.value = new File([blob], "my_image.png", {
            type: "image/png",
            lastModified: new Date().getTime(),
          });
          // file.value = blob;
          resolve(true);
        }, estensioneImmagineOriginale.value);
        console.log("canvas", canvas, file);
      } else {
        resolve(false);
      }
    });
  };
  const downloadImage = () => {
    const { canvas } = cropperHtmlElRef.value.getResult();
    if (canvas) {
      const form = new FormData();
      canvas.toBlob((blob) => {
        form.append("file", blob);
        // You can use axios, superagent and other libraries instead here
        //   const url = immagineCroppata.src;
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.target = "_blank";
        link.href = url;
        link.setAttribute(
          "download",
          `${nomeImmagineOriginale.value + "_cropped"}.${
            estensioneImmagineOriginale.value
          }`
        );
        document.body.appendChild(link);
        setTimeout(() => {
          link.click();
          document.body.removeChild(link);
        }, 500);
      }, estensioneImmagineOriginale.value);
    }
  };
  const flip = (x, y) => {
    cropperHtmlElRef.value.flip(x, y);
  };
  const rotate = (angle) => {
    cropperHtmlElRef.value.rotate(angle);
  };
  const zoom = (isZoom) => {
    if (isZoom) {
      cropperHtmlElRef.value.zoom(2);
      return;
    }
    cropperHtmlElRef.value.zoom(0.5);
  };
  const resetCropperPosition = () => {
    cropperHtmlElRef.value.reset();
  };

  const centerStencil = () => {
    cropperHtmlElRef.value.setCoordinates(({ coordinates, imageSize }) => ({
      left: imageSize.width / 2 - coordinates.width / 2,
      top: imageSize.height / 2 - coordinates.height / 2,
    }));
  };
  const maximizeStencil = () => {
    cropperHtmlElRef.value.setCoordinates(({ imageSize }) => ({
      width: imageSize.width,
      height: imageSize.height,
    }));
  };

  const removeGarbage = () => {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (image.value.src) {
      URL.revokeObjectURL(image.value.src);
    }
  };
  const resetImage = () => {
    image.value = {
      src: null,
      type: null,
    };
  };

  const maximizeStencilTimeout = () => {
    setTimeout(() => {
      if (!isRemovingGarbage.value) {
        cropperHtmlElRef.value.setCoordinates(({ imageSize }) => {
          console.log("imageSize", imageSize);
          return {
            width: imageSize ? imageSize.width : 0,
            height: imageSize ? imageSize.height : 0,
          };
        });
      }
    }, 500);
  };

  const getCroppedImage = async () => {
    const { coordinates, canvas } = cropperHtmlElRef.value.getResult();
    console.log(canvas.toDataURL());
    let blobToReturn = null;
    await canvas.toBlob((blob) => {
      console.log(blob);
      blobToReturn = blob;
    });
    return blobToReturn;
    // return canvas.toDataURL();
  };

  return {
    resetImage,
    centerStencil,
    maximizeStencil,
    resetCropperPosition,
    zoom,
    flip,
    rotate,
    downloadImage,
    uploadImage,
    setFileToSend,
    crop,
    reset,
    loadImage,
    image,
    cropperHtmlElRef,
    file,
    nomeImmagineOriginale,
    estensioneImmagineOriginale,
    removeGarbage,
    cropV2,
    getCroppedImage,
    maximizeStencilTimeout,
  };
}
